import React, {useEffect, useRef} from 'react';
import Image from './img/pexels-photo-2881261.jpeg'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';



const Test = () => {
    
    const ref = useRef(null);
    useEffect(() => {
        gsap.to('#container_test', {
            y : 900,
            duration: 2,
            ease: 'bounce',
            ScrollTrigger : {
                trigger: '#section_test',
                markers : true,

            }
        })
    }, [])
    return (
        <section id="section_test" className='section_test'>
            <div id="container_test" ref={ref} className="container_test">
                <img alt='modern' src={Image} />
            </div>
        </section>
    );
};

export default Test;