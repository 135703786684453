import Reveal from './Reveal';
import './css/style.scss';
import Test from './Test';


function App() {

  


  return (
    <div className="App">
       <Reveal />
       <Test />
    </div>
  );
}

export default App;
